import axios from "axios";
import { URL_API } from "../config/urls";
import { infoCodTK } from "../funciones/infotTK";
import { verificarCode, verificarCodeForAxios } from "../funciones/verificResponse";


export async function httpPost(data,url) {

    return axios.post(URL_API+url,data, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.status === 200) {
        return data.data;
      } else {
        console.log("$$$$$$$$$$$$$$$$$");
        verificarCodeForAxios(data );
        return null;
      }
    })
    .catch(err => {
      console.log("$$$$$$$$$######$$$$$$$$");
      verificarCodeForAxios(err.response);
      return null;
    });
  }


  export async function httpGet(url) {

    return axios.get(URL_API+url, {
      headers: {
        'Authorization': `Bearer ${infoCodTK()}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    }).then(data => {
      if (data.status === 200) {
        return data.data;
      } else {
        verificarCodeForAxios(data.data );
        return null;
      }
    })
    .catch(err => {
      verificarCodeForAxios(err.response);
      return null;
    });
  }