import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { useUsuario } from "../../context/UsuarioContext";
import { Link } from "react-router-dom";
import logo from '../../assets/icons/iconApp.png'

const TopBar=()=>{

  const { setDarkMode, setToken } = useUsuario();
  function cl() {
    setToken("");
  }

    return (
        <Navbar expand="lg" bg="primary" data-bs-theme="dark">
          <Container>
            <img src={logo} class="img-fluid" alt="..." style={{height:"30px"}}/>
            <Navbar.Brand>Nuevo Milenio</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link to={"/"} className="nav-link">Inicio</Link>
                <Link to={"/reporte"} className="nav-link">Reportes</Link>
                <Link to={"/cargarDatos"} className="nav-link">Cargar Datos</Link>
                <Nav.Link onClick={()=>cl()}>Cerrar Sesión</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );

}


export default TopBar;
