import { useState } from 'react';
import { nameTkLocalStorage } from '../config/dataGeneral';


export function useToken() {
    const getToken = () => {
      const tokenString = localStorage.getItem(nameTkLocalStorage);
      if(tokenString==="" || tokenString===null){
        return "";
      }
      const userToken = JSON.parse(tokenString);
      return userToken
      
    };
    
    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem(nameTkLocalStorage, JSON.stringify(userToken));
        setToken(userToken);
      };

      return {
        setToken: saveToken,
        token
      }
  }
