import React, { useState } from "react";
import { useToken } from "../hooks/useToken";

const UsuarioContext = React.createContext();

export function UsuarioProvider(props){
    
    const { token, setToken } = useToken();
    const [spinnerLoad, setSpinnerLoad]=useState(false);
    const [darkMode, setDarkMode]=useState(false);


      const value = React.useMemo(()=>{
        return({
            token,
            spinnerLoad,
            darkMode,
            setToken,
            setSpinnerLoad,
            setDarkMode,
        });
      },[darkMode,token,spinnerLoad,setToken]);

      return <UsuarioContext.Provider value={value} {...props} />
}

export function useUsuario(){
    const context = React.useContext(UsuarioContext);
    if(!context){
        throw new Error('useUsuario debe estar dentro del proveedor UsuarioContext');
    }
    return context;
}