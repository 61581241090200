import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import { httpGet, httpPost } from "../../http/request";
import { JsonToExcel } from "react-json-to-excel";
import { useUsuario } from "../../context/UsuarioContext";
import { redondearNumero } from "../../funciones/utils";

const Reporte = () => {

    const {setSpinnerLoad } = useUsuario();

    const tableRef = useRef(null);
    const [datos, setDatos] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setSpinnerLoad(true);
        httpGet("/reporteLista").then((data) => {
            if (data) {
                setDatos(data.reporte);
            }
            setSpinnerLoad(false);
        });
    }

    const descargarExcel = () => {

    }

    return (<Container>
        <Row>
            <Col>

                <Card className="mt-3">
                    <Card.Header>
                        Reporte
                    </Card.Header>
                    <Card.Body className="p-0">
                        <div className="m-2 text-end">

                            <JsonToExcel
                                title="Descargar en Excel"
                                data={datos}
                                fileName="reporte_de_lecturas"
                                btnClassName="btn"
                            />
                        </div>
                        <hr className="m-0" />

                        <Table size="sm" className="table-hover" style={{ fontSize: "12px" }} ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>N</th>
                                    <th className="text-center">CODIGO</th>
                                    <th className="text-center">MEDIDOR</th>
                                    <th className="text-center">NOMBRE</th>
                                    <th className="text-center">LECTURA_ANTERIOR</th>
                                    <th className="text-center">LECTURA_ACTUAL</th>
                                    <th className="text-center">PRECIO</th>
                                    <th className="text-center">PERIODO</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="text-start">{item.CODIGO ?? "( error )"}</td>
                                        <td className="text-end">{item.MEDIDOR ?? "( error )"}</td>
                                        <td className="text-start">{item.NOMBRE ?? "( error )"}</td>
                                        <td className="text-end">{item.LECTURA_ANTERIOR ?? "( error )"}</td>
                                        <td className="text-end">{item.LECTURA_ACTUAL ?? "( error )"}</td>
                                        <td className="text-end">{(item.PRECIO) ?? "( error )"}</td>
                                        <td className="text-end">{item.PERIODO ?? "( error )"}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>


                    </Card.Body>
                </Card>

            </Col>
        </Row>
    </Container>);
}

export default Reporte;