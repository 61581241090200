import { useRef, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import * as XLSX from "xlsx";
import uploadImg from '../../assets/icons/cloud-upload-regular-240.png';
import fileXlxs from '../../assets/icons/excel.jpg';
import './file.css';
import { MdOutlineDeleteForever } from 'react-icons/md';
import { httpPost } from "../../http/request";
import { useUsuario } from "../../context/UsuarioContext";

const CargarDatos = () => {


    const wrapperRef = useRef(null);
    
    const {setSpinnerLoad } = useUsuario();


    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const [file, setFile] = useState(null);
    const [datosValidos, setDatosValidos] = useState([]);


    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if (newFile) {

            const reader = new FileReader();

            reader.onload = event => {
                try {
                    const { result } = event.target;
                    const workbook = XLSX.read(result, { type: 'binary' });
                    let data = [];
                    for (const sheet in workbook.Sheets) {
                        if (workbook.Sheets.hasOwnProperty(sheet)) {
                            data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                        }
                    }
                    validar(data);
                } catch (e) {
                    console.log('Error al Extraer información del Documento');
                    return;
                }
            };
            reader.readAsBinaryString(newFile);
            setFile(newFile);
        }
    }

    const fileRemove = () => {
        setFile(null);
        setDatosValidos([]);
    }

    const validar = (values) => {
        let vals = []
        for (var i = 0; i < values.length; i++) {
            var dt = values[i];
            if (dt.CODIGO !== null && dt.CODIGO !== undefined &&
                dt.MEDIDOR !== null && dt.MEDIDOR !== undefined &&
                dt.NOMBRE !== null && dt.NOMBRE !== undefined &&
                dt.LECTURA_ANTERIOR !== null && dt.LECTURA_ANTERIOR !== undefined &&
                dt.CONSUMO_BASICO !== null && dt.CONSUMO_BASICO !== undefined &&
                dt.PRECIO_CONS_BASICO !== null && dt.PRECIO_CONS_BASICO !== undefined &&
                dt.PRECIO_CONS_ADICIONAL !== null && dt.PRECIO_CONS_ADICIONAL !== undefined &&
                dt.MANTENIMIENTO !== null && dt.MANTENIMIENTO !== undefined &&
                dt.PERIODO !== null && dt.PERIODO !== undefined) {

                    dt.UP = false;
                    vals.push(dt);
                //ver si existe en la lista de programados oficial
                /*for (var j = 0; j < props.ests.length; j++) {
                    if (dt.RU === props.ests[j].ru) {
                        dt.UP = false;
                        vals.push(dt);
                        break;
                    }
                }*/
            }
        }
        setDatosValidos(vals);
    };

    const cargarLista=(l)=>{
        
        var registros = "";
        for (var i = 0; i < l.length; i++) {
            registros += l[i].CODIGO + "--" 
            + l[i].MEDIDOR + "--"  
            + l[i].NOMBRE + "--"  
            + l[i].LECTURA_ANTERIOR + "--"  
            + l[i].CONSUMO_BASICO + "--"  
            + l[i].PRECIO_CONS_BASICO + "--"  
            + l[i].PRECIO_CONS_ADICIONAL + "--"  
            + l[i].MANTENIMIENTO + "--"  
            + l[i].PERIODO + "--"  
            + (l[i].DEUDAS!==undefined?l[i].DEUDAS:"");

            if (i < l.length - 1) {
                registros += "##";
            }
        }

        setSpinnerLoad(true);
        httpPost({ "data": registros}, "/registrarDatos").then((data) => {
            if (data) {
                alert("Carga Exitosa");
                setFile(null);
                setDatosValidos([]);
            }
            setSpinnerLoad(false);
        });
    }

    return (<Container>
        <Row>
            <Col>

                <Card className="mt-3">
                    <Card.Header>
                        Cargar datos al sistema
                    </Card.Header>
                    <Card.Body className="p-0">

                        <div>

                            {
                                file === null ? (
                                    <div
                                        ref={wrapperRef}
                                        className="drop-file-input"
                                        onDragEnter={onDragEnter}
                                        onDragLeave={onDragLeave}
                                        onDrop={onDrop}
                                    >
                                        <div className="drop-file-input__label">
                                            <img src={uploadImg} alt="" />
                                            <p>Arrastra y suelta tu archivo Excel aquí</p>
                                        </div>
                                        <input type="file" value="" onChange={onFileDrop} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                    </div>
                                ) : null
                            }

                            {
                                file !== null ? (
                                    <div className="drop-file-preview">
                                        <div>
                                            <div className="drop-file-preview__item">
                                                <img src={fileXlxs} alt="" />
                                                <div className="drop-file-preview__item__info">
                                                    <p>{file.name}</p>
                                                    <p>{file.size}B</p>
                                                </div>
                                                <span className="drop-file-preview__item__del" onClick={() => fileRemove()}><MdOutlineDeleteForever className="h3 text-danger" /></span>
                                            </div>
                                            <hr className="m-0"/>
                                            <Row>
                                                {datosValidos.length?<Col md={12} sm={12} className="text-end mt-3">
                                                <Button variant="success" className="me-3" onClick={()=>cargarLista(datosValidos)}>Cargar Lista</Button>
                                                </Col>:<></>}
                                                <Col md={12} sm={12} className="ms-2 text-muted">
                                                <span>{datosValidos.length} registros válidos encontrados.</span>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Table size="sm" className="" style={{fontSize:"12px"}}>
                                                <thead>
                                                    <tr>
                                                        <th>N</th>
                                                        <th className="text-center">CODIGO</th>
                                                        <th className="text-center">MEDIDOR</th>
                                                        <th className="text-center">NOMBRE</th>
                                                        <th className="text-center">LECTURA_ANTERIOR</th>
                                                        <th className="text-center">CONSUMO_BASICO</th>
                                                        <th className="text-center">PRECIO_BASICO</th>
                                                        <th className="text-center">PRECIO_ADICIONAL</th>
                                                        <th className="text-center">MANTENIMIENTO</th>
                                                        <th className="text-center">MES</th>
                                                        <th className="text-center">DEUDAS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {datosValidos.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index+1}</td>
                                                            <td className="text-start">{item.CODIGO ?? "( error )"}</td>
                                                            <td className="text-end">{item.MEDIDOR ?? "( error )"}</td>
                                                            <td className="text-start">{item.NOMBRE ?? "( error )"}</td>
                                                            <td className="text-end">{item.LECTURA_ANTERIOR ?? "( error )"}</td>
                                                            <td className="text-center">{item.CONSUMO_BASICO ?? "( error )"}</td>
                                                            <td className="text-center">{item.PRECIO_CONS_BASICO ?? "( error )"}</td>
                                                            <td className="text-center">{item.PRECIO_CONS_ADICIONAL ?? "( error )"}</td>
                                                            <td className="text-center">{item.MANTENIMIENTO ?? "( error )"}</td>
                                                            <td className="text-center">{item.PERIODO ?? "( error )"}</td>
                                                            <td className="text-center">{item.DEUDAS}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>


                    </Card.Body>
                </Card>

            </Col>
        </Row>
    </Container>);
}

export default CargarDatos;