
export async function verificarCode(d) {
  var dd =await d.json()
  switch (d.code) {
    case 403:
      localStorage.clear();
      window.location.reload(true);
      break;
    case 200:
      break;
    default:
      if(dd.error){
        alert(dd.error);
      }
      console.log(`Sorry, we are out of ${d.code}.`);
  }
}

export async function verificarCodeForAxios(d) {
  if(d){
    switch (d.status) {
      case 403:
        localStorage.clear();
        window.location.reload(true);
        break;
      case 200:
        break;
      default:
        var dd =d.data
        if(dd.error){
          alert(dd.error);
        }
        console.log(`Sorry, we are out of ${d.code}.`);
    }
  }else{
    alert("Ocurrió un error en el sistema");
  }
}

