import { Button, Card, Col, Container, Form, Modal, Row, Tab, Table } from "react-bootstrap";
import { SpinnerLoad } from "../../components/spinnerLoad/SpinnerLoad";
import { useEffect, useState } from "react";
import { httpPost } from "../../http/request";
import { FaCheckCircle } from "react-icons/fa";
import { useUsuario } from "../../context/UsuarioContext";
import { printZpl } from "../../funciones/printZpl";
import { redondearNumero } from "../../funciones/utils";

const Home = () => {


    const { setSpinnerLoad } = useUsuario();

    const [modalNew, setModalNew] = useState(false);
    const [selectDataModalNew, setSelectDataModalNew] = useState({});

    const [codigoSearch, setCodigoSearh] = useState("");

    const [listaClientes, setListaClientes] = useState([]);

    const selectCliente = (d) => {
        setModalNew(true);
        setSelectDataModalNew(d);
    }

    const buscar = (d) => {
        if (!d) {
            alert("debe ingresar datos");
            return;
        }
        setListaClientes([]);
        setSpinnerLoad(true);
        httpPost({ "texto": d }, "/buscarCliente").then((d) => {
            if (d) {
                setListaClientes(d.usuarios);
            }
            setSpinnerLoad(false);
        });
    }

    const listItems = (l) => l.map((d, j) => <tr key={j} onClick={(e) => selectCliente(d)} className="pointer">
        <td>
            <p className="m-0 fw-bold">{d.codigo} - {d.nombre}</p>
            <p className="m-0 text-muted fs-6">Medidor: <span className="fw-bold">{d.medidor}</span></p>
        </td>
        <td className="">
            {d.lectura_actual ? <span className="fs-4 text-success"><FaCheckCircle /></span> : <></>}
        </td>
    </tr>);

    return (<div className="">
        <Container>
            <Row>
                <Col sm={12} md={6}>
                    <Card className="mt-3">
                        <Card.Body>
                            <Form.Label htmlFor="inputNum5">Número de medidor</Form.Label>
                            <Form.Control
                                type="text"
                                className="fs-1 text-center"
                                id="inputNum5"
                                aria-describedby="passwordHelpBlock"
                                value={codigoSearch}
                                onChange={(e) => setCodigoSearh(e.target.value)}
                            />
                            <Form.Text id="passwordHelpBlock" muted>
                                Ingrese el código del medidor a sin espacios.
                            </Form.Text>
                            <div className="text-center mt-3">
                                <Button onClick={() => buscar(codigoSearch)}>Buscar cliente</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={6}>
                    <Card className="mt-3">
                        <Card.Header>
                            Resultados de la búsqueda
                        </Card.Header>
                        <Card.Body className="p-0">
                            <Table className="table-hover align-middle">
                                <tbody>
                                    {listItems(listaClientes)}
                                </tbody>
                            </Table>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>


        <ModalNewRegistro
            show={modalNew}
            onHide={() => setModalNew(false)}
            dataSelect={selectDataModalNew}
        />

    </div>);

}


const ModalNewRegistro = (props) => {


    const [load, setLoad] = useState(false);
    const [nuevaLectura, setNuevaLectura] = useState();
    const [cliente, setCliente] = useState({});


    useEffect(() => {

        if (props.show) {
            setCliente([]);
            loadCliente();
        }

    }, [props.show, props.dataSelect]);

    const loadCliente = () => {
        setNuevaLectura();
        setLoad(true);
        httpPost({ "code": props.dataSelect.id_lect }, "/getCliente").then((data) => {
            if (data) {
                setCliente(data.cliente);
            } else {
                props.onHide();
            }
            setLoad(false);
        });
    }

    const registrarLec = (dd) => {
        var d = parseFloat(dd, 10);
        if (!d) {
            alert("debe ingresar datos");
            return;
        }
        if (d < cliente.lectura_anterior) {
            alert("La lectura " + d + " no puede ser menor a la anterior");
            return;
        }

        setLoad(true);
        httpPost({ "code": cliente.id_lect, "lectura": d }, "/registrarLectura").then((data) => {
            if (data) {
                setCliente(data.cliente);
            }
            setLoad(false);
        });
    }

    const printBoleta = () => {

        httpPost({ "code": cliente.id_lect }, "/boletaPago").then((data) => {
            if (data) {
                printZpl(data.zpl);
            }
        });
    }


    return (
        <Modal show={props.show} onHide={props.onHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size='lg'
            backdrop="static"
            keyboard={false}>

            <Modal.Header>
                <Modal.Title className="col-12 m-0">
                    <p className="text-center">REGISTRAR NUEVA LECTURA</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p className="m-0 fw-bold">{props.dataSelect.codigo} - {props.dataSelect.nombre}</p>

                </div>
                {load ?
                    <SpinnerLoad />
                    :
                    <fieldset>

                        <Table className="align-middle">
                            <tbody>
                                <tr>
                                    <td>Medidor: </td>
                                    <td className="fw-bold">{cliente.medidor}</td>
                                    <td>Lectura anterior: </td>
                                    <td className="fw-bold">{cliente.lectura_anterior}</td>
                                </tr>
                                {cliente.lectura_actual ? <tr>
                                    <td>Lectura actual: </td>
                                    <td className="fw-bold text-danger">{cliente.lectura_actual}</td>
                                    
                                    <td colSpan={2}className="text-center">
                                                <Button variant="success" onClick={() => printBoleta()}> Imprimir Boleta</Button>
                                    </td>
                                </tr> : <></>}
                            </tbody>
                        </Table>

                        <div className="row">

                            <div className="mt-1">
                                <Form.Label htmlFor="inputNum5">{cliente.lectura_actual ?"Modificar lectura":"Registrar lectura"}</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="fs-1 text-center"
                                    id="inputNum5"
                                    aria-describedby="passwordHelpBlock"
                                    value={nuevaLectura}
                                    onChange={(e) => setNuevaLectura(e.target.value)}
                                />
                                <Form.Text id="passwordHelpBlock" muted>
                                    Verifique correctamente los datos antes de confirmar.
                                </Form.Text>
                                <div className="text-center mt-3 mb-4">
                                    <Button onClick={() => registrarLec(nuevaLectura)}>Confirmar Lectura</Button>

                                </div>

                            </div>

                            <div>
                                {cliente.lectura_actual ?<Table className="table-responsive table-borderless">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="text-center">C_M3</th>
                                            <th className="text-center">C_BS</th>
                                            <th className="text-center">M_BS</th>
                                            <th className="text-center">TOT_BS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center">{(cliente.lectura_actual-cliente.lectura_anterior).toFixed(2)}</td>
                                            <td className="text-center">{redondearNumero(cliente.pago_calculado-cliente.mantenimiento)}</td>
                                            <td className="text-center">{cliente.mantenimiento}</td>
                                            <td className="text-center">{(cliente.pago_calculado)}</td>
                                        </tr>
                                    </tbody>
                                </Table>:<></>}
                            </div>

                        </div>
                    </fieldset>
                }


            </Modal.Body>
            {load ? <></> : <Modal.Footer className='p-0'>
                <div className='row col-12 m-0 p-0'>
                    <div onClick={props.onHide} className='col-12 btn-ios rounded-bottom'>Cerrar</div>
                </div>
            </Modal.Footer>}
        </Modal>
    );
}



export default Home;