import { loginAccess_url } from "../config/urls";
import { verificarCode } from "../funciones/verificResponse";

export async function loginAcc(data) {
  return fetch(loginAccess_url, {
    method: 'POST',
    /*headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
    },*/
    body: JSON.stringify(data)
  })
    .then(data => {
      console.log("------------------------");
      if (data.ok && data.status === 200) {
        console.log(data);
        return data.json();
      } else {
        verificarCode(data );
        return null;
      }
    })
    .catch(err => {
      console.log(err);
      //toastError("Ocurrió un error al conectar con el servidor");
    })

  }