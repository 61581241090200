export const SpinnerLoad = () => {

    return(<div>
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
            </div>
        </div>
        <br/>
        <div className="d-flex justify-content-center">
            <strong>Procesando...</strong>
        </div>
    </div>);
}