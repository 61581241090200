import { useState } from "react";
import * as FaIcons from 'react-icons/fa';
import './login.css';
import { Card } from "react-bootstrap";
import { useUsuario } from "../../context/UsuarioContext";
import { loginAcc } from "../../http/requestLogin";
import logo from '../../assets/icons/iconApp.png'

const Login = () => {

    const [password, setPassword] = useState();
    const [username, setUserName] = useState();

    const [loginError, setLoginError] = useState("");

    const { setToken, setSpinnerLoad } = useUsuario();


    const handleSubmit = async e => {
        e.preventDefault();

              setSpinnerLoad(true);
              
             let data = await loginAcc({
                "user":username,
                "pass":password
              });      
              if(data!=null ){
                if(data.token){
                  setToken(data.token);
                }else{
                  setLoginError("data.mensaje");
                }
              }
              setSpinnerLoad(false);
              
    }

    return (

        <div className='login d-flex align-items-center'>
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="login-box col-md-4 bg-white m-3 shadow mb-5 bg-body rounded">
                        <Card.Header>
                            <div className="text-center mt-3">
                                <img src={logo} class="img-fluid" alt="..." style={{height:"70px"}}/>
                            </div>
                            <p className="login-box-msg m-0 h1 fs-2 text-center">NUEVO MILENIO</p>
                            <p className="login-box-msg m-0 mb-5 h3 fs-6 text-muted text-center">COMITÉ DE AGUA POTABLE</p>
                        </Card.Header>
                        <Card.Body className="text-center px-4 pb-4">

                            <form onSubmit={handleSubmit} className="">
                                <div className="input-group mb-3">
                                    <input onChange={e => setUserName(e.target.value)} type="text" className="form-control" placeholder="Usuario" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                    <span className="input-group-text" id="basic-addon2"><FaIcons.FaUserAlt /></span>
                                </div>

                                <div className="input-group mb-2">
                                    <input onChange={e => setPassword(e.target.value)} name="password" placeholder="Password" type="password" id="password" className="form-control" />
                                    <span className="input-group-text" id="basic-addon2"><FaIcons.FaLock /></span>
                                </div>

                                <div className='mb-3 text-danger font12'>
                                    <strong>{loginError}</strong>
                                </div>

                                <div className="row">
                                    <div className="col-12 align-self-end">
                                        <button type="submit" className="btn btn-primary btn-block" >Acceder</button>
                                    </div>
                                </div>

                            </form>
                        </Card.Body>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Login;