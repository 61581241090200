import { Button, Container } from "react-bootstrap";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper-global";

const PrintTest = () => {

    const pri = () => {
        printBarcode("0123456789");
    }

    const printBarcode = async (serial) => {
        try {

            // Create a new instance of the object
            const browserPrint = new ZebraBrowserPrintWrapper();

            // Select default printer
            const defaultPrinter = await browserPrint.getDefaultPrinter();

            // Set the printer
            browserPrint.setPrinter(defaultPrinter);

            // Check printer status
            const printerStatus = await browserPrint.checkPrinterStatus();

            // Check if the printer is ready
            if (printerStatus.isReadyToPrint) {

                // ZPL script to print a simple barcode

                const zpl2 = `^XA

                ^FX Top section with logo, name and address.
                ^CF0,60
                ^FO20,20^FDEPSAS COBIJA^FS
                ^CF0,20
                ^FO20,80^FDAVISO DE COBRANZA #235 TKN^FS
                ^FO20,100^GB570,2,2^FS
                ^FX Second section con datos personales del cliente.
                ^CF1,14
                ^FO20,110^FDNOMBRE:^FS
                ^FO120,110^FDDIXSON GONZALO VILLCA CHOQUE^FS
                ^FO20,130^FDDIRECCION:^FS
                ^FO140,130^FDZONA ANTOFAGASTA^FS
                ^FO20,150^FDCOD. CLIENTE:^FS
                ^FO180,150^FD22685^FS
                ^FO310,150^FDNUM. MEDIDOR:^FS
                ^FO470,150^FD19001704^FS
                ^FO20,180^GB570,1,1^FS
                ^CF0,30
                ^FO220,515^FD1000 Shipping Lane^FS
                ^FO50,250^GB500,3,3^FS
                
                ^FX Second section with recipient address and permit information.
                ^CFA,30
                ^FO50,300^FDJohn Doe^FS
                ^FO50,340^FD100 Main Street^FS
                ^FO50,380^FDSpringfield TN 39021^FS
                ^FO50,420^FDUnited States (USA)^FS
                ^CFA,15
                ^FO400,300^GB150,150,3^FS
                ^FO438,340^FDPermit^FS
                ^FO438,390^FD123456^FS
                ^FO50,500^GB500,3,3^FS
                
                ^FX Third section with bar code.
                ^BY4,2,270
                ^FO50,550^BC^FD12345678^FS
                
                ^FX Fourth section (the two boxes on the bottom).
                ^FO50,900^GB700,250,3^FS
                ^FO400,900^GB3,250,3^FS
                ^CF0,40
                ^FO100,960^FDCtr. X34B-1^FS
                ^FO100,1010^FDREF1 F00B47^FS
                ^FO100,1060^FDREF2 BL4H8^FS
                ^CF0,190
                ^FO470,955^FDCA^FS
                
                ^XZ`;

                browserPrint.print(zpl2);
            } else {
                alert(printerStatus.errors);
                console.log("Error/s", printerStatus.errors);
            }

        } catch (error) {
            alert(error);
            throw new Error(error);
        }
    };


    return (
        <div>
            <Container className="mt-3">
                <Button onClick={() => pri()}>imprimir prueba</Button>
            </Container>
        </div>
    );

}

export default PrintTest;