import { Route, Routes } from 'react-router-dom';
import './App.css';
import TopBar from './layouts/topBar/TopBar';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import PrintTest from './pages/printTest/PrintTest';
import { UsuarioProvider, useUsuario } from './context/UsuarioContext';
import CargarDatos from './pages/cargarDatos/CargarDatos';
import Reporte from './pages/reporte/Reporte';
import ModalLoad from './components/spinnerLoad/ModalLoad';

export default () => <UsuarioProvider>
  <App></App>
</UsuarioProvider>

function App() {

  const { token, darkMode } = useUsuario();

  if (!token) {

    return (
      <div>
        <Routes basename={'/'}>
          <Route exact path="/*" element={<Login />} />
        </Routes>
        <ModalLoad />
      </div>
    )

  }

  return (
    <div className="App">
      <TopBar />
      <div>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/print" element={<PrintTest />} />
          <Route path="/cargarDatos" element={<CargarDatos />} />
          <Route path="/reporte" element={<Reporte />} />
        </Routes>
      </div>
      <ModalLoad />
    </div>
  );
}

