import { Modal, Spinner, } from "react-bootstrap";
import { useUsuario } from "../../context/UsuarioContext";


function ModalLoad(){

  const {spinnerLoad, setSpinnerLoad } = useUsuario();
  
    return(
        <Modal 
        className="modal-global-load"
        size="sm" 
        backdropClassName="modal-global-load"
        show={spinnerLoad} 
        onHide={()=>setSpinnerLoad(false)} 
        centered 
        contentClassName="modal-global-load bg-transparent border-0"
        backdrop="static"
        keyboard={false}>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col align-self-center text-center">
                <Spinner animation="grow" variant="light" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <br/>
                <div className="text-center text-white fw-bold">Cargando la información...</div>
              </div>
            </div>
          </div>
          
        </Modal.Body>
          
      </Modal>
    );
}

export default ModalLoad;